import { useState, useRef, useEffect } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import client from '../../../utils/sanityClient';
import PageWrapper from '../PageWrapper/PageWrapper';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import { Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import {
  breakpoints,
  colors,
  durations,
  fontSizes,
  lineHeights,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';
import { useInterval } from '../../../utils/customReactHooks';
import Section from '../../molecules/Section/Section';

const QuoteLogoSliderV1 = ({
  bgImage,
  customCss,
  hide,
  quoteEntityReferenceFieldArray,
  sectionStyles,
}) => {
  const [state, setQuoteEntityImages] = useState({ quoteImages: [] });
  const { quoteImages } = state;

  useEffect(() => {
    const getQuoteImages = async quoteEntitys => {
      const quoteEntityIds = quoteEntitys.map(quoteEntity => quoteEntity._id);
      const quoteEntityIdsString = quoteEntityIds
        .map(id => `'${id}'`)
        .join(',');
      const query = `*[_type=="clientEntity" && references(${quoteEntityIdsString})].clientLogoWhite`;
      await client.fetch(query).then(clientEntityImages => {
        const images = clientEntityImages.reduce(
          (acc, clientEntityImage, index) => {
            acc[quoteEntityIds[index]] = clientEntityImage;
            return acc;
          },
          {}
        );
        setQuoteEntityImages({ quoteImages: images });
      });
    };
    getQuoteImages(quoteEntityReferenceFieldArray);
  }, []);

  const [autoplayInterval, setAutoplayInterval] = useState(false);
  const refs = {}; // object to hold dynamic dom references for each logo and quote
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalQuotes = quoteEntityReferenceFieldArray.length;

  const quoteLogoSliderBackgroundImage = sanityImage(bgImage)
    .width(1600)
    .url();

  const quoteLogoSliderCss = css`
    padding-top: ${sectionStyles && sectionStyles.paddingTop
      ? sectionSettings[sectionStyles.paddingTop]
      : uc('100px')};
    padding-bottom: ${sectionStyles && sectionStyles.paddingBottom
      ? sectionSettings[sectionStyles.paddingBottom]
      : uc('100px')};
    text-align: center;
    background-image: url(${quoteLogoSliderBackgroundImage});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;

    @media (${breakpoints.tablet}) {
      padding-top: ${uc('20px')};

      ul {
        min-height: ${uc('100px')};
      }

      > div {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  `;

  const logosCss = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    list-style: none outside;

    li {
      margin: ${uc('0 40px')};
      cursor: pointer;
      opacity: 0.2;
      transition: all ${durations.slow} ease-in-out;

      &.active-quote {
        opacity: 100;
      }

      @media (${breakpoints.tablet}) {
        display: none;
        margin: ${uc('50px 40px 0')};

        &.active-quote {
          display: block;
          min-height: ${uc('100px')};
        }
      }
    }

    li.quoteDot {
      width: ${uc('10px')};
      height: ${uc('10px')};
      margin: ${uc('0 8px')};
      background: ${colors.white};
      border-radius: ${uc('50px')};

      @media (${breakpoints.tablet}) {
        display: flex;
        margin: ${uc('10px 8px 20px')};

        &.active-quote {
          min-height: 0;
        }
      }
    }
  `;

  const quotesCss = css`
    position: relative;
    max-width: ${uc('925px')};
    min-height: ${uc('40px')};
    margin: 0 auto ${uc('100px')};
    text-align: center;
    list-style: none outside;

    @media (${breakpoints.tablet}) {
      width: 100%;
      min-height: ${uc('160px')};
    }

    li {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      color: ${colors.white};
      opacity: 0;
      transition: all ${durations.slow} ease-in-out;

      &.active-quote {
        opacity: 1;
      }

      @media (${breakpoints.mobile}) {
        padding: 0 ${uc('20px')};
      }
      ${customCss.li}
    }

    h3 {
      color: ${colors.white};
      line-height: ${lineHeights.copy};
      ${customCss.h3}
    }
  `;

  /**
   * Remove active-quote class from oldIndex and apply to newIndex.
   *
   * @param oldIndex
   * @param newIndex
   */
  const toggleActiveQuoteAndLogo = (oldIndex, newIndex) => {
    setAutoplayInterval(false); // TODO: somehow this is important, but should be refactored
    if (!refs[`quote${oldIndex}`].current) {
      return;
    }
    refs[`quote${oldIndex}`].current.classList.remove('active-quote');
    refs[`logo${oldIndex}`].current.classList.remove('active-quote');
    refs[`quote${newIndex}`].current.classList.add('active-quote');
    refs[`logo${newIndex}`].current.classList.add('active-quote');
    setCurrentIndex(parseInt(newIndex, 10));
  };

  /**
   * Handle click events on logos.
   *
   * @param e
   *  The event that was triggered.
   */
  const handleLogoClick = e => {
    e.preventDefault();
    // TODO: re-enable this functionality
    clearInterval(autoplayInterval);
    setAutoplayInterval(false);
    const clickedLogo = e.target.parentElement;
    const clickedLogoIndex = clickedLogo.getAttribute('data-index');
    toggleActiveQuoteAndLogo(currentIndex, clickedLogoIndex);
  };

  /**
   * Rotate the quotes automatically.
   */
  const autoplay = () => {
    if (!autoplayInterval) {
      useInterval(() => {
        const currentIndexPlus1 = currentIndex + 1;
        const newIndex =
          currentIndexPlus1 > totalQuotes - 1 ? 0 : currentIndexPlus1;
        toggleActiveQuoteAndLogo(currentIndex, newIndex);
      }, 8000);
    }
  };

  autoplay(); // start the autoplay.

  /**
   * Render the list of quotes.
   */
  const renderedQuotes = quoteEntityReferenceFieldArray.map(
    ({ author, quoteText }, index) => {
      const quoteClass = index === 0 ? 'active-quote' : '';
      const quoteId = `client-quote-${index}`;
      refs[`quote${index}`] = useRef(null);

      return (
        <li
          key={quoteId}
          className={quoteClass}
          id={quoteId}
          ref={refs[`quote${index}`]}
        >
          <Heading3 color={colors.lightGray.two}>{quoteText}</Heading3>
          {hide && author.length > 0 && (
            <Text fontSize={fontSizes.eighteen} color={colors.white}>
              {author}
            </Text>
          )}
        </li>
      );
    }
  );

  /**
   * Render the list of logos.
   */
  const renderedLogos = quoteEntityReferenceFieldArray.map(
    ({ _id, quoteImage, quoteText }, index) => {
      const logoClass = index === 0 ? 'active-quote' : '';
      const logoId = `client-logo-${index}`;
      refs[`logo${index}`] = useRef(null);

      const logo = quoteImages[_id] || quoteImage;

      if (logo && !hide) {
        return (
          <li
            key={logoId}
            id={logoId}
            className={logoClass}
            data-index={index}
            ref={refs[`logo${index}`]}
          >
            <Image
              src={sanityImage(logo)
                .auto('format')
                .width(140)
                .url()}
              alt={quoteText}
              onClick={e => handleLogoClick(e)}
              onKeyDown={e => handleLogoClick(e)}
            />
          </li>
        );
      }

      return (
        <li
          key={logoId}
          id={logoId}
          className={!hide ? ['quoteDot', logoClass].join(' ') : ''}
          data-index={index}
          ref={refs[`logo${index}`]}
        />
      );
    }
  );

  /**
   * Component output.
   */
  return (
    <Section
      sectionStyles={sectionStyles}
      className="quote-logo-slider"
      css={quoteLogoSliderCss}
    >
      <PageWrapper>
        <ul className="quotes" css={quotesCss}>
          {renderedQuotes}
        </ul>
        <ul className="logos" css={logosCss}>
          {renderedLogos}
        </ul>
      </PageWrapper>
    </Section>
  );
};

/**
 * Prop types.
 */
QuoteLogoSliderV1.propTypes = {
  bgImage: PropTypes.shape({}),
  hide: PropTypes.bool.isRequired,
  quoteEntityReferenceFieldArray: PropTypes.arrayOf(
    PropTypes.shape({
      quoteImage: PropTypes.shape({}),
      attribution: PropTypes.string,
      quoteText: PropTypes.string.isRequired,
    })
  ).isRequired,
  customCss: PropTypes.shape({
    h3: PropTypes.string,
    li: PropTypes.string,
  }),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

QuoteLogoSliderV1.defaultProps = {
  bgImage: null,
  customCss: {},
  sectionStyles: {},
};

export default QuoteLogoSliderV1;
